.navigation{
  padding: 40px 130px;
  transition: .3s ease;
  &.nav-bg {
    background-color: $primary-color;
    padding: 15px 100px;
    @include desktop {
      padding: 15px 20px;
    }
  }
  @include desktop {
    padding: 20px;
    background: $primary-color;
  }
}

.navbar{
  .nav-item{
    .nav-link{
      font-family: $tertiary-font;
      text-transform: uppercase;
      padding: 15px;
    }
    &.active{
      font-weight: bold;
    }
  }
}

.navbar-dark .navbar-nav .nav-link{
  color: $white;
}