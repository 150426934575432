.card-lg {
  flex-direction: row-reverse;
  align-items: center;

  @include desktop {
    flex-direction: column;
  }
}

blockquote {
  display: inline-block;
  padding: 10px 20px;
  background: $gray;
  border-left: 2px solid $primary-color;
  font-style: italic;
  font-size: 22px;
}

.content {
  * {
    margin-bottom: 20px;
  }

  strong {
    font-family: $secondary-font;
    font-size: 22px;
    font-weight: normal;
    color: $text-color-dark;
    display: inherit;
    line-height: 1.5;
  }
}